import styled from "styled-components";
import {Link} from "gatsby";

export const List = styled.ul`
	width: 100%;
	height: 100%;
	margin: 0;
	list-style-type: none;
`;

export const Post = styled(Link)`
	width: 100%;
	height: 100%;
	max-height: 225px;
	display: flex;
	justify-content: flex-start;
	border: 3px solid ${props => props.theme.colors.primary};
	overflow: hidden;
	text-decoration: none;

	@media(${props => props.theme.breakPoints.md}){
		max-height: 165px;
	}

	& .gatsby-image-wrapper {
		height: auto;
		width: auto;
		border: 2px solid #1a725e;

		@media(${props => props.theme.breakPoints.md}){
			flex-shrink: 0;
		}

		& img {
			height: auto;
			width: auto;
		}
	}

	& img {
		height: 225px !important;
		width: 270px !important;
		margin: 0;
		object-fit: cover;

		@media(${props => props.theme.breakPoints.md}){
			height: 160px !important;
			width: 230px !important;
		}

		@media(${props => props.theme.breakPoints.sm}){
			width: 170px !important;
		}
	}
`;

export const Info = styled.div`
	max-width: 70%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-left: 20px;

	@media(${props => props.theme.breakPoints.sm}){
		padding-left: 7px;
	}

	& h4 {
		margin: 0;
		padding: 5px;
		width: 100%;
		text-align: left;
		height: fit-content;

		@media(${props => props.theme.breakPoints.md}){
			font-size: 1.7rem;
		}

		@media(${props => props.theme.breakPoints.md}){
			font-size: 1.1rem;
		}
	}

	& span {
		color: ${props => props.theme.colors.secondary};
	}

	p {
		flex-grow: 1;

		@media(${props => props.theme.breakPoints.sm}){
			margin: 5px;
		}

	}
`;

export const Links = styled.div`
    margin: 0 auto;
    width: 90%;
    height: 30px;
    display: flex;
    justify-content: space-between;

    & a {
        width: 100px;
        text-align: center;
        font-size: 1.2rem;
        text-decoration: none;
        color: ${props => props.theme.colors.primary};
    }
`;