import React from 'react';
import { graphql, Link } from 'gatsby';
import { PageWrapper, Container, Title } from 'styles/page';
import {List, Post, Info, Links} from "styles/blog";
import {Sidebar} from "components";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Seo from "utils/seo";
import {GetSocials} from "utils/convertData";

export const query = graphql`
	query BlogQuery($limit: Int, $skip: Int) {
		allPrismicBlogPost(sort: {fields: first_publication_date, order: DESC}, limit: $limit, skip: $skip) {
			nodes {
				...blogQuery
			}
		}
		prismicNavigation {
            data {
                ...socialQuery
            }
        }
		prismicSidebar {
            ...sidebarFragment
        }
	}
`;



const Blog = ({data, pageContext}) => {
    const posts = data?.allPrismicBlogPost?.nodes;
	const sidebarData = data?.prismicSidebar?.data;
	const {currentPage, numPages} = pageContext;

	const isFirst = currentPage === 1;
	const isLast = currentPage === numPages;
	const prevPagePath = currentPage - 1 === 1 ? `/blog/` : `/blog/${currentPage - 1}`;
	const nextPagePath = `/blog/${currentPage +1}`; 
	
	const social = GetSocials(data);

	const description = posts[0]?.data?.meta_description?.split(" ").splice(0,25).map(w => w + " ").join("");

	return (
		<>
            <Seo/>
			<PageWrapper>
			<Container>
				<Title>Blog</Title>
				<List>
                {posts.map((post, i) => (
					<li>
						<Post key={`blog post : ${currentPage} - ${i}`} to={`/blog/${post.uid}`}>
								<GatsbyImage image={getImage(post.data.meta_image.localFile)} alt={post.data.meta_image.alt}/>
								<Info>
									<h4>{post.data.title}</h4>
									{!!post.data.meta_description &&
									<p>{description} ... <span>Read More</span></p>
								}
								</Info>
						</Post>
					</li>
				))}
				</List>
				<Links>
				{!isFirst &&
				<Link to={prevPagePath}>Previous</Link>
			}
				{!isLast &&
				<Link to={nextPagePath}>Next</Link>
			}
			</Links>
			</Container>
			<Sidebar data={sidebarData} social={social}/>
		</PageWrapper>
			</>
	);
};

export default Blog;
